import React, { useState, useEffect } from "react";
import "./ReajusteModal.css";

const ModalAvisoReajuste = () => {
    const [reajusteModal, setReajusteModal] = useState(false);

    useEffect(() => {
        // Remover a verificação do localStorage para que o modal apareça sempre
        setReajusteModal(true);
    }, []);

    const fecharReajusteModal = () => {
        setReajusteModal(false);
        // Comentar a linha abaixo para não salvar no localStorage
        // localStorage.setItem("avisoReajusteVisualizado", "true");
    };

    return (
        <>
            {reajusteModal && (
                <div className="modal-aviso-overlay">
                    <div className="modal-aviso-content">
                        <h2>Aviso de <span>Reajuste</span></h2>
                        <p>Os valores do <span>plano coletivo por adesão</span> serão reajustados em <span>novembro</span>.</p>
                        <button onClick={fecharReajusteModal} className="btn-fechar-aviso">
                            Fechar
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default ModalAvisoReajuste;