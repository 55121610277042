const RedesSantos = [
    {
        especialidade: "Acupuntura",
        estabelecimentos: [
            {
                nome: "Isaac e De Poli de Serviços Medicos",
                endereco: "Rua Barão de Paranapiaçaba, 233 Encruzilhada, Santos / SP - CEP 11050-251",
                telefones: ["(13) 3324-8353"],
            },
        ],
    },
    {
        especialidade: "Alergia e Imunologia",
        estabelecimentos: [
            {
                nome: "Antonio Jose Gouveia",
                endereco: "Avenida Ana Costa, 254, Gonzaga, Santos / SP - CEP 11060-001",
                telefones: ["(13) 3228-3999"],
            },
        ],
    },
    {
        especialidade: "Alergia e Imunologia Pediátrica",
        estabelecimentos: [
            {
                nome: "Antonio Jose Gouveia",
                endereco: "Avenida Ana Costa, 254, Gonzaga, Santos / SP - CEP 11060-001",
                telefones: ["(13) 3228-3999"],
            },
        ],
    },
    {
        especialidade: "Anatomia Patológica",
        estabelecimentos: [
            {
                nome: "APAS - Santos",
                endereco: "Rua Carvalho De Mendonça, 335, Vila Belmiro, Santos / SP - CEP 11070-101",
                telefones: ["(13) 3226-5000"],
            },
            {
                nome: "Biolab Laboratório de Analclin",
                endereco: "Avenida Ana Costa, 259, Encruzilhada, Santos / SP - CEP 11060-907",
                telefones: ["(13) 3251-8080"],
            },
            {
                nome: "Biolab Santos",
                endereco: "Avenida Afonso Pena, 314, Boqueirão, Santos / SP - CEP 11020-000",
                telefones: ["(13) 3301-1832"],
            },
            {
                nome: "Eurofns Clinical Diagnostics",
                endereco: "Avenida Ana Costa, 424, Gonzaga, Santos / SP - CEP 11060-002",
                telefones: ["(13) 2127-7000"],
            },
            {
                nome: "Laboratório Clínico Santa Clara",
                endereco: "Avenida Ana Costa, 165, Vila Matias, Santos / SP - CEP 11060-001",
                telefones: ["(13) 3221-8124", "(13) 99183-0475"],
            },
            {
                nome: "Laboratório Gonzaga",
                endereco: "Avenida Ana Costa, 493, Gonzaga, Santos / SP - CEP 11060-003",
                telefones: ["(13) 3222-6151", "(13) 3284-0122", "(13) 99771-1230"],
            },
        ],
    },
    {
        especialidade: "Anestesiologia",
        estabelecimentos: [
            {
                nome: "APAS - Santos",
                endereco: "Rua Carvalho De Mendonça, 335, Vila Belmiro, Santos / SP - CEP 11070-101",
                telefones: ["(13) 3226-5000"],
            },
        ],
    },
    {
        especialidade: "Cardiologia",
        estabelecimentos: [
            {
                nome: "APAS - Santos",
                endereco: "Rua Carvalho De Mendonça, 335, Vila Belmiro, Santos / SP - CEP 11070-101",
                telefones: ["(13) 3226-5000"],
            },
            {
                nome: "Cardio Imagem de Santos",
                endereco: "Rua Barão de Paranapiaçaba, 233, Encruzilhada, Santos / SP - CEP 11050-251",
                telefones: ["(13) 3288-1900"],
            },
            {
                nome: "Centro Clínico Frei Galvão - Santos",
                endereco: "Rua Doutor Heitor de Moraes, 15, Boqueirão, Santos / SP - CEP 11045-570",
                telefones: ["(13) 4090-1680"],
            },
            {
                nome: "Centro Médico Popular Saúde da Família Santos",
                endereco: "Avenida Nossa Senhora de Fátima, 555, Chico de Paula, Santos / SP - CEP 11085-203",
                telefones: ["0800 521-0101"],
            },
            {
                nome: "Clínica Radiológica de Santos",
                endereco: "Avenida Conselheiro Nébias, 521, Santos / SP - CEP 11045-001",
                telefones: ["(13) 3228-2525"],
            },
            {
                nome: "Santa Casa de Santos",
                endereco: "Rua Doutor Cláudio Luís da Costa, 50, Jabaquara, Santos / SP - CEP 11075-900",
                telefones: ["(13) 3202-0600"],
            },
        ],
    },
    {
        especialidade: "Cirurgia Buco-Maxilo-Facial",
        estabelecimentos: [
            {
                nome: "Guilherme Ramos Costa PJ",
                endereco: "Avenida Senador Feijó, 600, Vila Matias, Santos / SP - CEP 11015-504",
                telefones: ["(13) 99753-3430"],
            },
        ],
    },
    {
        especialidade: "Cirurgia de Cabeça e Pescoço",
        estabelecimentos: [
            {
                nome: "APAS - Santos",
                endereco: "Rua Carvalho De Mendonça, 335, Vila Belmiro, Santos / SP - CEP 11070-101",
                telefones: ["(13) 3226-5000"],
            },
            {
                nome: "Centro Clínico Frei Galvão - Santos",
                endereco: "Rua Doutor Heitor de Moraes, 15, Boqueirão, Santos / SP - CEP 11045-570",
                telefones: ["(13) 4090-1680"],
            },
        ],
    },
    {
        especialidade: "Cirurgia Geral",
        estabelecimentos: [
            {
                nome: "APAS - Santos",
                endereco: "Rua Carvalho De Mendonça, 335, Vila Belmiro, Santos / SP - CEP 11070-101",
                telefones: ["(13) 3226-5000"],
            },
            {
                nome: "Centro Clínico Frei Galvão - Santos",
                endereco: "Rua Doutor Heitor de Moraes, 15, Boqueirão, Santos / SP - CEP 11045-570",
                telefones: ["(13) 4090-1680"],
            },
            {
                nome: "EGO - Consultório Médico",
                endereco: "Rua Barão de Paranapiaçaba, 233, Encruzilhada, Santos / SP - CEP 11050-251",
                telefones: ["(13) 3231-2303"],
            },
            {
                nome: "Santa Casa de Santos",
                endereco: "Rua Doutor Cláudio Luís da Costa, 50, Jabaquara, Santos / SP - CEP 11075-900",
                telefones: ["(13) 3202-0600"],
            },
        ],
    },
    {
        especialidade: "Cirurgia Geral Odontológica",
        estabelecimentos: [
            {
                nome: "Centro Santista de Odontologia",
                endereco: "Avenida Pedro Lessa, 1920, Aparecida, Santos / SP - CEP 11025-002",
                telefones: [],
            },
            {
                nome: "Doutor Dentista Santos Clinica Odontológica",
                endereco: "Rua Delphin Moreira, 389, Embaré, Santos / SP - CEP 11040-101",
                telefones: ["(13) 3223-5154"],
            },
            {
                nome: "Implafacil Clinic",
                endereco: "Avenida Marechal Floriano Peixoto, 44, Gonzaga, Santos / SP - CEP 11060-910",
                telefones: ["(13) 3284-4325"],
            },
            {
                nome: "MDF Odontologia Ltda",
                endereco: "Rua Oswaldo Cochrane, 48, Embaré, Santos / SP - CEP 11040-110",
                telefones: ["(13) 97418-7518"],
            },
            {
                nome: "Solange Maria Mazza",
                endereco: "Avenida Ana Costa, 521, Gonzaga, Santos / SP - CEP 11060-003",
                telefones: ["(13) 3877-7541", "(13) 98223-1687"],
            },
        ],
    },
    {
        especialidade: "Cirurgia Plástica",
        estabelecimentos: [
            {
                nome: "APAS - Santos",
                endereco: "Rua Carvalho De Mendonça, 335, Vila Belmiro, Santos / SP - CEP 11070-101",
                telefones: ["(13) 3226-5000"],
            },
        ],
    },
    {
        especialidade: "Cirurgia Torácica",
        estabelecimentos: [
            {
                nome: "Centro Clínico Frei Galvão - Santos",
                endereco: "Rua Doutor Heitor de Moraes, 15, Boqueirão, Santos / SP - CEP 11045-570",
                telefones: ["(13) 4090-1680"],
            },
        ],
    },
    {
        especialidade: "Cirurgia Vascular",
        estabelecimentos: [
            {
                nome: "APAS - Santos",
                endereco: "Rua Carvalho De Mendonça, 335, Vila Belmiro, Santos / SP - CEP 11070-101",
                telefones: ["(13) 3226-5000"],
            },
            {
                nome: "Centro Clínico Frei Galvão - Santos",
                endereco: "Rua Doutor Heitor de Moraes, 15, Boqueirão, Santos / SP - CEP 11045-570",
                telefones: ["(13) 4090-1680"],
            },
            {
                nome: "Santa Casa de Santos",
                endereco: "Rua Doutor Cláudio Luís da Costa, 50, Jabaquara, Santos / SP - CEP 11075-900",
                telefones: ["(13) 3202-0600"],
            },
            {
                nome: "Santos Medical Group",
                endereco: "Avenida Rei Alberto I, 243, Ponta da Praia, Santos / SP - CEP 11030-381",
                telefones: ["(13) 3269-0020", "(13) 3877-7337"],
            },
        ],
    },
    {
        especialidade: "Citopatologia",
        estabelecimentos: [
            {
                nome: "Biolab Laboratório de Analclin",
                endereco: "Avenida Ana Costa, 259, Encruzilhada, Santos / SP - CEP 11060-907",
                telefones: ["(13) 3251-8080"],
            },
            {
                nome: "Biolab Santos",
                endereco: "Avenida Afonso Pena, 314, Boqueirão, Santos / SP - CEP 11020-000",
                telefones: ["(13) 3301-1832"],
            },
            {
                nome: "Eurofns Clinical Diagnostics",
                endereco: "Avenida Ana Costa, 424, Gonzaga, Santos / SP - CEP 11060-002",
                telefones: ["(13) 2127-7000"],
            },
            {
                nome: "Laboratório Clínico Santa Clara",
                endereco: "Avenida Ana Costa, 165, Vila Matias, Santos / SP - CEP 11060-001",
                telefones: ["(13) 3221-8124", "(13) 99183-0475"],
            },
            {
                nome: "Laboratório Gonzaga",
                endereco: "Avenida Ana Costa, 493, Gonzaga, Santos / SP - CEP 11060-003",
                telefones: ["(13) 3222-6151", "(13) 3284-0122", "(13) 99771-1230"],
            },
        ],
    },
    {
        especialidade: "Clínica Geral Odontológica",
        estabelecimentos: [
            {
                nome: "Alessandra Scurti de Almeida Lima",
                endereco: "Rua Fernão Dias, 12, Gonzaga, Santos / SP - CEP 11055-220",
                telefones: ["(13) 99683-5783"],
            },
            {
                nome: "Ana Carolina Tavares da Silva",
                endereco: "Avenida Afonso Pena, 167, Boqueirão, Santos / SP - CEP 11020-000",
                telefones: ["(13) 3233-9829"],
            },
            {
                nome: "Ana Paula Tavares",
                endereco: "Avenida Afonso Pena, 167, Embaré, Santos / SP - CEP 11020-001",
                telefones: ["(13) 3233-9829", "(13) 99749-5015"],
            },
            {
                nome: "Ariany de Franca Ferreira",
                endereco: "Avenida Washington Luís, 316, Gonzaga, Santos / SP - CEP 11055-000",
                telefones: ["(13) 3394-0524", "(13) 99112-1070"],
            },
            {
                nome: "Belato Mendes Odontologia Ltda",
                endereco: "Rua Goiás, 225, Gonzaga, Santos / SP - CEP 11050-101",
                telefones: [],
            },
            {
                nome: "Bertoni Serviços Odontologicos",
                endereco: "Avenida Jovino de Mello, 653, Areia Branca, Santos / SP - CEP 11089-001",
                telefones: ["(13) 3299-2090"],
            },
            {
                nome: "Camila Werner Kozseran",
                endereco: "Rua Alexandre Martins, 71, Aparecida, Santos / SP - CEP 11025-201",
                telefones: ["(13) 99140-3773"],
            },
            {
                nome: "Carla Fukuda de Almeida",
                endereco: "Avenida Almirante Cochrane, 194, Aparecida, Santos / SP - CEP 11040-002",
                telefones: ["(13) 3019-5657", "(13) 99718-3332"],
            },
            {
                nome: "Centro Santista de Odontologia",
                endereco: "Avenida Pedro Lessa, 1920, Aparecida, Santos / SP - CEP 11025-002",
                telefones: [],
            },
            {
                nome: "Claudia Angela Iglesias Monteiro Perez",
                endereco: "Avenida Marechal Floriano Peixoto, 20 Gonzaga, Santos / SP - CEP 11060-300",
                telefones: ["(13) 3284-7023"],
            },
            {
                nome: "Daniel Monte Real",
                endereco: "Avenida Pedro Lessa, 1111, Ponta da Praia, Santos / SP - CEP 11025-001",
                telefones: ["(13) 99662-2338"],
            },
            {
                nome: "Daniele Rodrigues Toniete",
                endereco: "Rua Guaião, 66, Aparecida, Santos / SP - CEP 11035-260",
                telefones: [],
            },
            {
                nome: "Debora Ferreira de Cicco",
                endereco: "Avenida Conselheiro Nebias, 754, Boqueirão, Santos / SP - CEP 11045-770",
                telefones: ["(13) 3223-8133", "(13) 99153-2623"],
            },
            {
                nome: "Denise Moraes dos Santos",
                endereco: "Avenida Senador Pinheiro Machado, 589 Marapé, Santos / SP - CEP 11075-001",
                telefones: ["(13) 3237-3368"],
            },
            {
                nome: "Doutor Dentista Santos Clinica Odontológica",
                endereco: "Rua Delphin Moreira, 389, Embaré, Santos / SP - CEP 11040-101",
                telefones: ["(13) 3223-5154"],
            },
            {
                nome: "Eduardo Jamar de Queiroz",
                endereco: "Rua Goitacazes, 06, Gonzaga, Santos / SP - CEP 11055-210",
                telefones: ["(13) 3284-4325"],
            },
            {
                nome: "Elisabete Gambero Gaspar",
                endereco: "Rua Doutor Carvalho de Mendonça, 373, Vila Belmiro, Santos / SP - CEP 11070-101",
                telefones: ["(13) 3224-5301", "(13) 98226-2222"],
            },
            {
                nome: "Fabiana Saloio Machado Lopes",
                endereco: "Rua Doutor Tolentino Filgueiras, 119, Gonzaga, Santos / SP - CEP 11060-471",
                telefones: ["(13) 3877-5803", "(13) 99118-1499", "(13) 99163-1978"],
            },
            {
                nome: "Gabrielle Segui Marcelino dos Santos",
                endereco: "Avenida Conselheiro Nébias, 703, Boqueirão, Santos / SP - CEP 11045-003",
                telefones: ["(13) 98106-0250"],
            },
            {
                nome: "Guilherme Ramos Costa PJ",
                endereco: "Avenida Senador Feijó, 600, Vila Matias, Santos / SP - CEP 11015-504",
                telefones: ["(13) 99753-3430"],
            },
            {
                nome: "Implafacil Clinic",
                endereco: "Avenida Marechal Floriano Peixoto, 44, Gonzaga, Santos / SP - CEP 11060-910",
                telefones: ["(13) 3284-4325"],
            },
            {
                nome: "Karine Fausto Gonzalez",
                endereco: "Avenida Marechal Deodoro, 532, Gonzaga, Santos / SP - CEP 11060-400",
                telefones: ["(13) 99730-9095"],
            },
            {
                nome: "Kellen Cristina de Oliveira",
                endereco: "Avenida Santista, 541, Morro de Nova Cintra, Santos / SP - CEP 11080-000",
                telefones: ["(13) 3025-7058", "(13) 99678-1306"],
            },
            {
                nome: "Lilian Simone Pereira da Costa",
                endereco: "Avenida Ana Costa, 493, Gonzaga, Santos / SP - CEP 11060-911",
                telefones: ["(13) 99728-9089"],
            },
            {
                nome: "MDF Odontologia Ltda",
                endereco: "Rua Oswaldo Cochrane, 48, Embaré, Santos / SP - CEP 11040-110",
                telefones: ["(13) 97418-7518"],
            },
            {
                nome: "Maria Aparecida Pace",
                endereco: "Rua Alexandre Martins, 143, Aparecida, Santos / SP - CEP 11025-201",
                telefones: ["(13) 3238-7736", "(13) 3469-7557"],
            },
            {
                nome: "Marjory Alfaro Nascimento",
                endereco: "Rua São José, 36, Embaré, Santos / SP - CEP 11040-200",
                telefones: [],
            },
            {
                nome: "Mary Marajo Dal Secchi Fernandes",
                endereco: "Avenida Ana Costa, 482 Gonzaga, Santos / SP - CEP 11060-002",
                telefones: ["(13) 3284-3790"],
            },
            {
                nome: "Ortopraia",
                endereco: "Rua Professor Pirajá da Silva, 149, Aparecida, Santos / SP - CEP 11035-200",
                telefones: ["(13) 3227-7088", "(13) 97403-0391"],
            },
            {
                nome: "Patricia Sakamoto",
                endereco: "Avenida Pedro Lessa, 3076, Embaré, Santos / SP - CEP 11050-002",
                telefones: ["(13) 3014-3181", "(13) 99708-1835"],
            },
            {
                nome: "Ricardo dos Reis Fernandes da Silva",
                endereco: "Avenida Ana Costa, 458 Gonzaga, Santos / SP - CEP 11060-002",
                telefones: ["(13) 3323-0273"],
            },
            {
                nome: "Sandrine Nicola Mathias de Oliveira",
                endereco: "Rua Ministro João Mendes, 11040 Embaré, Santos / SP - CEP 11040-260",
                telefones: ["(19) 98183-0505", "(19) 99136-6488"],
            },
            {
                nome: "Smyle Odontologia",
                endereco: "Rua Monsenhor de Paula Rodrigues, 92, Vila Belmiro, Santos / SP - CEP 11075-350",
                telefones: ["(13) 99677-1077"],
            },
            {
                nome: "Solange Maria Mazza",
                endereco: "Avenida Ana Costa, 521, Gonzaga, Santos / SP - CEP 11060-003",
                telefones: ["(13) 3877-7541", "(13) 98223-1687"],
            },
            {
                nome: "Thaina Marina Ribeiro Paredes",
                endereco: "Avenida Ana Costa, 254, Vila Matias, Santos / SP - CEP 11060-000",
                telefones: ["(13) 99161-9314"],
            },
            {
                nome: "Thais Santos Schroll",
                endereco: "Avenida Senador Feijó, 686, Vila Matias, Santos / SP - CEP 11015-504",
                telefones: ["(13) 3223-1601", "(13) 99127-0778"],
            },
        ]
    },
    {
        especialidade: "Clínica Médica",
        estabelecimentos: [
            {
                nome: "APAS - Santos",
                endereco: "Rua Carvalho De Mendonça, 335, Vila Belmiro, Santos / SP - CEP 11070-101",
                telefones: ["(13) 3226-5000"],
            },
            {
                nome: "Centro Clínico Frei Galvão - Santos",
                endereco: "Rua Doutor Heitor de Moraes, 15, Boqueirão, Santos / SP - CEP 11045-570",
                telefones: ["(13) 4090-1680"],
            },
            {
                nome: "Centro Médico Popular Saúde da Família Santos",
                endereco: "Avenida Nossa Senhora de Fátima, 555, Chico de Paula, Santos / SP - CEP 11085-203",
                telefones: ["0800 521-0101"],
            },
            {
                nome: "Clínica Saúde Ideal LTDA",
                endereco: "Avenida Ana Costa, 259, Gonzaga, Santos / SP - CEP 11060-001",
                telefones: ["(13) 3341-4321"],
            },
            {
                nome: "Derma Santos",
                endereco: "Avenida Ana Costa, 120, Vila Matias, Santos / SP - CEP 11060-000",
                telefones: ["(13) 3221-4244"],
            },
            {
                nome: "Hospital Frei Galvão - Santos",
                endereco: "Rua Doutor Heitor de Moraes, 19 Boqueirão, Santos / SP - CEP 11045-570",
                telefones: ["(13) 3229-1500", "(11) 4090-1680"],
            },
            {
                nome: "Santa Casa de Santos",
                endereco: "Rua Doutor Cláudio Luís da Costa, 50, Jabaquara, Santos / SP - CEP 11075-900",
                telefones: ["(13) 3202-0600"],
            },
            {
                nome: "Santos Medical Group",
                endereco: "Avenida Rei Alberto I, 243, Ponta da Praia, Santos / SP - CEP 11030-381",
                telefones: ["(13) 3269-0020", "(13) 3877-7337"],
            },
        ],
    },
    {
        especialidade: "Coloproctologia",
        estabelecimentos: [
            {
                nome: "APAS - Santos",
                endereco: "Rua Carvalho De Mendonça, 335, Vila Belmiro, Santos / SP - CEP 11070-101",
                telefones: ["(13) 3226-5000"],
            },
            {
                nome: "Centro Clínico Frei Galvão - Santos",
                endereco: "Rua Doutor Heitor de Moraes, 15, Boqueirão, Santos / SP - CEP 11045-570",
                telefones: ["(13) 4090-1680"],
            },
            {
                nome: "Centro Médico Popular Saúde da Família Santos",
                endereco: "Avenida Nossa Senhora de Fátima, 555, Chico de Paula, Santos / SP - CEP 11085-203",
                telefones: ["0800 521-0101"],
            },
            {
                nome: "Santa Casa de Santos",
                endereco: "Rua Doutor Cláudio Luís da Costa, 50, Jabaquara, Santos / SP - CEP 11075-900",
                telefones: ["(13) 3202-0600"],
            },
        ],
    },
    {
        especialidade: "Coluna",
        estabelecimentos: [
            {
                nome: "APAS - Santos",
                endereco: "Rua Carvalho De Mendonça, 335, Vila Belmiro, Santos / SP - CEP 11070-101",
                telefones: ["(13) 3226-5000"],
            },
        ],
    },
    {
        especialidade: "Densitometria Óssea",
        estabelecimentos: [
            {
                nome: "Brasil Imagem Medicinadiagnóstica",
                endereco: "Avenida Ana Costa, 259, Gonzaga, Santos / SP - CEP 11060-001",
                telefones: ["(13) 3235-7658", "(13) 3235-7805"],
            },
            {
                nome: "Clínica Radiológica de Santos",
                endereco: "Avenida Conselheiro Nébias, 521, Santos / SP - CEP 11045-001",
                telefones: ["(13) 3228-2525"],
            },
            {
                nome: "Mega Imagem",
                endereco: "Rua Mato Grosso, 79, Boqueirão, Santos / SP - CEP 11055-010",
                telefones: ["(13) 3202-1990"],
            },
            {
                nome: "Vila Rica Clínica de Imagem",
                endereco: "Avenida Doutor Bernardino de Campos, 16, Vila Belmiro, Santos / SP - CEP 11065-000",
                telefones: ["(13) 3257-2300"],
            },
        ],
    },
    {
        especialidade: "Dentística",
        estabelecimentos: [
            {
                nome: "Alessandra Scurti de Almeida Lima",
                endereco: "Rua Fernão Dias, 12, Gonzaga, Santos / SP - CEP 11055-220",
                telefones: ["(13) 99683-5783"],
            },
            {
                nome: "Ana Carolina Tavares da Silva",
                endereco: "Avenida Afonso Pena, 167, Boqueirão, Santos / SP - CEP 11020-000",
                telefones: ["(13) 3233-9829"],
            },
            {
                nome: "Ana Paula Tavares",
                endereco: "Avenida Afonso Pena, 167, Embare, Santos / SP - CEP 11020-001",
                telefones: ["(13) 3233-9829", "(13) 99749-5015"],
            },
            {
                nome: "Ariany de Franca Ferreira",
                endereco: "Avenida Washington Luís, 316, Gonzaga, Santos / SP - CEP 11055-000",
                telefones: ["(13) 3394-0524", "(13) 99112-1070"],
            },
            {
                nome: "Belato Mendes Odontologia Ltda",
                endereco: "Rua Goiás, 225, Gonzaga, Santos / SP - CEP 11050-101",
                telefones: [],
            },
            {
                nome: "Camila Werner Kozseran",
                endereco: "Rua Alexandre Martins, 71, Aparecida, Santos / SP - CEP 11025-201",
                telefones: ["(13) 99140-3773"],
            },
            {
                nome: "Carla Fukuda de Almeida",
                endereco: "Avenida Almirante Cochrane, 194, Aparecida, Santos / SP - CEP 11040-002",
                telefones: ["(13) 3019-5657", "(13) 99718-3332"],
            },
            {
                nome: "Centro Santista de Odontologia",
                endereco: "Avenida Pedro Lessa, 1920, Aparecida, Santos / SP - CEP 11025-002",
                telefones: [],
            },
            {
                nome: "Croma Centro de Radiologia Odontológica - Afonso Pena",
                endereco: "Avenida Afonso Pena, 81, Boqueirão, Santos / SP - CEP 11020-000",
                telefones: ["(13) 3223-3490"],
            },
            {
                nome: "Croma Centro de Radiologia Odontológica - Gonzaga",
                endereco: "Avenida Ana Costa, 493, Gonzaga, Santos / SP - CEP 11060-003",
                telefones: [],
            },
            {
                nome: "Daniel Monte Real",
                endereco: "Avenida Pedro Lessa, 1111, Ponta da Praia, Santos / SP - CEP 11025-001",
                telefones: ["(13) 99662-2338"],
            },
            {
                nome: "Daniele Rodrigues Toniete",
                endereco: "Rua Guaião, 66, Aparecida, Santos / SP - CEP 11035-260",
                telefones: [],
            },
            {
                nome: "Debora Ferreira de Cicco",
                endereco: "Avenida Conselheiro Nebias, 754, Boqueirão, Santos / SP - CEP 11045-770",
                telefones: ["(13) 3223-8133", "(13) 99153-2623"],
            },
            {
                nome: "Eduardo Jamar de Queiroz",
                endereco: "Rua Goitacazes, 06, Gonzaga, Santos / SP - CEP 11055-210",
                telefones: ["(13) 3284-4325"],
            },
            {
                nome: "Elisabete Gambero Gaspar",
                endereco: "Rua Doutor Carvalho de Mendonça, 373, Vila Belmiro, Santos / SP - CEP 11070-101",
                telefones: ["(13) 3224-5301", "(13) 98226-2222"],
            },
            {
                nome: "Fabiana Saloio Machado Lopes",
                endereco: "Rua Doutor Tolentino Filgueiras, 119, Gonzaga, Santos / SP - CEP 11060-471",
                telefones: ["(13) 3877-5803", "(13) 99118-1499", "(13) 99163-1978"],
            },
            {
                nome: "Gabrielle Segui Marcelino dos Santos",
                endereco: "Avenida Conselheiro Nébias, 703, Boqueirão, Santos / SP - CEP 11045-003",
                telefones: ["(13) 98106-0250"],
            },
            {
                nome: "Guilherme Ramos Costa PJ",
                endereco: "Avenida Senador Feijó, 600, Vila Matias, Santos / SP - CEP 11015-504",
                telefones: ["(13) 99753-3430"],
            },
            {
                nome: "Implafacil Clinic",
                endereco: "Avenida Marechal Floriano Peixoto, 44, Gonzaga, Santos / SP - CEP 11060-910",
                telefones: ["(13) 3284-4325"],
            },
            {
                nome: "Karine Fausto Gonzalez",
                endereco: "Avenida Marechal Deodoro, 532, Gonzaga, Santos / SP - CEP 11060-400",
                telefones: ["(13) 99730-9095"],
            },
            {
                nome: "Kellen Cristina de Oliveira",
                endereco: "Avenida Santista, 541, Morro de Nova Cintra, Santos / SP - CEP 11080-000",
                telefones: ["(13) 3025-7058", "(13) 99678-1306"],
            },
            {
                nome: "Lilian Simone Pereira da Costa",
                endereco: "Avenida Ana Costa, 493, Gonzaga, Santos / SP - CEP 11060-911",
                telefones: ["(13) 99728-9089"],
            },
            {
                nome: "MDF Odontologia Ltda",
                endereco: "Rua Oswaldo Cochrane, 48, Embaré, Santos / SP - CEP 11040-110",
                telefones: ["(13) 97418-7518"],
            },
            {
                nome: "Maria Aparecida Pace",
                endereco: "Rua Alexandre Martins, 143, Aparecida, Santos / SP - CEP 11025-201",
                telefones: ["(13) 3238-7736", "(13) 3469-7557"],
            },
            {
                nome: "Marjory Alfaro Nascimento",
                endereco: "Rua São José, 36, Embaré, Santos / SP - CEP 11040-200",
                telefones: [],
            },
            {
                nome: "Ortopraia",
                endereco: "Rua Professor Pirajá da Silva, 149, Aparecida, Santos / SP - CEP 11035-200",
                telefones: ["(13) 3227-7088", "(13) 97403-0391"],
            },
            {
                nome: "Patricia Sakamoto",
                endereco: "Avenida Pedro Lessa, 3076, Embare, Santos / SP - CEP 11050-002",
                telefones: ["(13) 3014-3181", "(13) 99708-1835"],
            },
            {
                nome: "Smyle Odontologia",
                endereco: "Rua Monsenhor de Paula Rodrigues, 92, Vila Belmiro, Santos / SP - CEP 11075-350",
                telefones: ["(13) 99677-1077"],
            },
            {
                nome: "Solange Maria Mazza",
                endereco: "Avenida Ana Costa, 521, Gonzaga, Santos / SP - CEP 11060-003",
                telefones: ["(13) 3877-7541", "(13) 98223-1687"],
            },
            {
                nome: "Thais Santos Schroll",
                endereco: "Avenida Senador Feijó, 686, Vila Matias, Santos / SP - CEP 11015-504",
                telefones: ["(13) 3223-1601", "(13) 99127-0778"],
            },
        ],
    },
    {
        especialidade: "Dermatologia",
        estabelecimentos: [
            {
                nome: "APAS - Santos",
                endereco: "Rua Carvalho De Mendonça, 335, Vila Belmiro, Santos / SP - CEP 11070-101",
                telefones: ["(13) 3226-5000"],
            },
            {
                nome: "Centro Médico Popular Saúde da Família Santos",
                endereco: "Avenida Nossa Senhora de Fátima, 555, Chico de Paula, Santos / SP - CEP 11085-203",
                telefones: ["0800 521-0101"],
            },
            {
                nome: "Clínica Saúde Ideal LTDA",
                endereco: "Avenida Ana Costa, 259, Gonzaga, Santos / SP - CEP 11060-001",
                telefones: ["(13) 3341-4321"],
            },
            {
                nome: "Derma Santos",
                endereco: "Avenida Ana Costa, 120, Vila Matias, Santos / SP - CEP 11060-000",
                telefones: ["(13) 3221-4244"],
            },
        ],
    },
    {
        especialidade: "Dor",
        estabelecimentos: [
            {
                nome: "Santa Casa de Santos",
                endereco: "Rua Doutor Cláudio Luís da Costa, 50, Jabaquara, Santos / SP - CEP 11075-900",
                telefones: ["(13) 3202-0600"],
            },
        ],
    },
    {
        especialidade: "Ecocardiografia",
        estabelecimentos: [
            {
                nome: "Cardio Imagem de Santos",
                endereco: "Rua Barão de Paranapiaçaba, 233, Encruzilhada, Santos / SP - CEP 11050-251",
                telefones: ["(13) 3288-1900"],
            },
            {
                nome: "Centro Clínico Frei Galvão - Santos",
                endereco: "Rua Doutor Heitor de Moraes, 15, Boqueirão, Santos / SP - CEP 11045-570",
                telefones: ["(13) 4090-1680"],
            },
            {
                nome: "Santos Medical Group",
                endereco: "Avenida Rei Alberto I, 243, Ponta da Praia, Santos / SP - CEP 11030-381",
                telefones: ["(13) 3269-0020", "(13) 3877-7337"],
            },
            {
                nome: "Vila Rica Clínica de Imagem",
                endereco: "Avenida Doutor Bernardino de Campos, 16, Vila Belmiro, Santos / SP - CEP 11065-000",
                telefones: ["(13) 3257-2300"],
            },
        ],
    },
    {
        especialidade: "Ecografia Vascular com Doppler",
        estabelecimentos: [
            {
                nome: "Brasil Imagem Medicinadiagnóstica",
                endereco: "Avenida Ana Costa, 259, Gonzaga, Santos / SP - CEP 11060-001",
                telefones: ["(13) 3235-7658", "(13) 3235-7805"],
            },
            {
                nome: "Mega Imagem",
                endereco: "Rua Mato Grosso, 79, Boqueirão, Santos / SP - CEP 11055-010",
                telefones: ["(13) 3202-1990"],
            },
            {
                nome: "Santos Medical Group",
                endereco: "Avenida Rei Alberto I, 243, Ponta da Praia, Santos / SP - CEP 11030-381",
                telefones: ["(13) 3269-0020", "(13) 3877-7337"],
            },
            {
                nome: "Vila Rica Clínica de Imagem",
                endereco: "Avenida Doutor Bernardino de Campos, 16, Vila Belmiro, Santos / SP - CEP 11065-000",
                telefones: ["(13) 3257-2300"],
            },
        ],
    },
    {
        especialidade: "Endocrinologia",
        estabelecimentos: [
            {
                nome: "APAS - Santos",
                endereco: "Rua Carvalho De Mendonça, 335, Vila Belmiro, Santos / SP - CEP 11070-101",
                telefones: ["(13) 3226-5000"],
            },
            {
                nome: "Centro Médico Popular Saúde da Família Santos",
                endereco: "Avenida Nossa Senhora de Fátima, 555, Chico de Paula, Santos / SP - CEP 11085-203",
                telefones: ["0800 521-0101"],
            },
            {
                nome: "Katia Cristina Vecchi Coelho",
                endereco: "Avenida Conselheiro Nébias, 726, Boqueirão, Santos / SP - CEP 11045-002",
                telefones: ["(13) 3224-2294", "(13) 99753-3819"],
            },
            {
                nome: "Santa Casa de Santos",
                endereco: "Rua Doutor Cláudio Luís da Costa, 50, Jabaquara, Santos / SP - CEP 11075-900",
                telefones: ["(13) 3202-0600"],
            },
            {
                nome: "Santos Medical Group",
                endereco: "Avenida Rei Alberto I, 243, Ponta da Praia, Santos / SP - CEP 11030-381",
                telefones: ["(13) 3269-0020", "(13) 3877-7337"],
            },
        ],
    },
    {
        especialidade: "Endodontia",
        estabelecimentos: [
            {
                nome: "Alessandra Scurti de Almeida Lima",
                endereco: "Rua Fernão Dias, 12, Gonzaga, Santos / SP - CEP 11055-220",
                telefones: ["(13) 99683-5783"],
            },
            {
                nome: "Ariany de Franca Ferreira",
                endereco: "Avenida Washington Luís, 316, Gonzaga, Santos / SP - CEP 11055-000",
                telefones: ["(13) 3394-0524", "(13) 99112-1070"],
            },
            {
                nome: "Camila Werner Kozseran",
                endereco: "Rua Alexandre Martins, 71, Aparecida, Santos / SP - CEP 11025-201",
                telefones: ["(13) 99140-3773"],
            },
            {
                nome: "Centro Santista de Odontologia",
                endereco: "Avenida Pedro Lessa, 1920, Aparecida, Santos / SP - CEP 11025-002",
                telefones: [],
            },
            {
                nome: "Daniel Monte Real",
                endereco: "Avenida Pedro Lessa, 1111, Ponta da Praia, Santos / SP - CEP 11025-001",
                telefones: ["(13) 99662-2338"],
            },
            {
                nome: "Debora Ferreira de Cicco",
                endereco: "Avenida Conselheiro Nebias, 754, Boqueirão, Santos / SP - CEP 11045-770",
                telefones: ["(13) 3223-8133", "(13) 99153-2623"],
            },
            {
                nome: "Eduardo Jamar de Queiroz",
                endereco: "Rua Goitacazes, 06, Gonzaga, Santos / SP - CEP 11055-210",
                telefones: ["(13) 3284-4325"],
            },
            {
                nome: "Elisabete Gambero Gaspar",
                endereco: "Rua Doutor Carvalho de Mendonça, 373, Vila Belmiro, Santos / SP - CEP 11070-101",
                telefones: ["(13) 3224-5301", "(13) 98226-2222"],
            },
            {
                nome: "Fabiana Saloio Machado Lopes",
                endereco: "Rua Doutor Tolentino Filgueiras, 119, Gonzaga, Santos / SP - CEP 11060-471",
                telefones: ["(13) 3877-5803", "(13) 99118-1499", "(13) 99163-1978"],
            },
            {
                nome: "Implafacil Clinic",
                endereco: "Avenida Marechal Floriano Peixoto, 44, Gonzaga, Santos / SP - CEP 11060-910",
                telefones: ["(13) 3284-4325"],
            },
            {
                nome: "Kellen Cristina de Oliveira",
                endereco: "Avenida Santista, 541, Morro de Nova Cintra, Santos / SP - CEP 11080-000",
                telefones: ["(13) 3025-7058", "(13) 99678-1306"],
            },
            {
                nome: "Lilian Simone Pereira da Costa",
                endereco: "Avenida Ana Costa, 493, Gonzaga, Santos / SP - CEP 11060-911",
                telefones: ["(13) 99728-9089"],
            },
            {
                nome: "Melissa Karin Menegon",
                endereco: "Avenida Almirante Cochrane, 194, Aparecida, Santos / SP - CEP 11040-002",
                telefones: [],
            },
            {
                nome: "Ortopraia",
                endereco: "Rua Professor Pirajá da Silva, 149, Aparecida, Santos / SP - CEP 11035-200",
                telefones: ["(13) 3227-7088", "(13) 97403-0391"],
            },
            {
                nome: "Thaina Marina Ribeiro Paredes",
                endereco: "Avenida Ana Costa, 254, Vila Matias, Santos / SP - CEP 11060-000",
                telefones: ["(13) 99161-9314"],
            },
        ],
    },
    {
        especialidade: "Endoscopia Digestiva",
        estabelecimentos: [
            {
                nome: "Endosantos",
                endereco: "Avenida Doutor Bernardino de Campos, 47, Vila Belmiro, Santos / SP - CEP 11065-910",
                telefones: ["(13) 3302-0611", "(13) 99120-0506"],
            },
        ],
    },
    {
        especialidade: "Fisioterapia",
        estabelecimentos: [
            {
                nome: "ALMAI Instituto de Neurodesenvolvimento Infantil",
                endereco: "Avenida Doutor Pedro Lessa, 2797, Embaré, Santos / SP - CEP 11025-015",
                telefones: ["(13) 3227-2200", "(13) 99690-9009"],
            },
            {
                nome: "ALMAI Instituto de Neurodesenvolvimento Infantil",
                endereco: "Avenida Conselheiro Nébias, 421, Encruzilhada, Santos / SP - CEP 11045-001",
                telefones: ["(13) 99750-6555", "(13) 99808-8850"],
            },
            {
                nome: "Fisiocardio",
                endereco: "Avenida Conselheiro Nébias, 726, Boqueirão, Santos / SP - CEP 11045-002",
                telefones: ["(13) 3014-2916"],
            },
            {
                nome: "Foncare Santos",
                endereco: "Avenida Ana Costa, 101, Gonzaga, Santos / SP - CEP 11060-001",
                telefones: ["0800 771-2915"],
            },
            {
                nome: "Renata Salvador Martho",
                endereco: "Rua Presidente Prudente de Moraes, 75, Vila Matias, Santos / SP - CEP 11075-251",
                telefones: ["(13) 99709-7814"],
            },
        ],
    },
    {
        especialidade: "Fisioterapia - Cardio-Vascular",
        estabelecimentos: [
            {
                nome: "Renata Salvador Martho",
                endereco: "Rua Presidente Prudente de Moraes, 75, Vila Matias, Santos / SP - CEP 11075-251",
                telefones: ["(13) 99709-7814"],
            },
        ],
    },
    {
        especialidade: "Fisioterapia - Ortopédica",
        estabelecimentos: [
            {
                nome: "Medicina do Trauma e Reabilitação Santista",
                endereco: "Rua Alexandre Herculano, 204, Gonzaga, Santos / SP - CEP 11050-031",
                telefones: ["(13) 3234-2349", "(13) 3234-2441"],
            },
            {
                nome: "Renata Salvador Martho",
                endereco: "Rua Presidente Prudente de Moraes, 75, Vila Matias, Santos / SP - CEP 11075-251",
                telefones: ["(13) 99709-7814"],
            },
        ],
    },
    {
        especialidade: "Fonoaudiologia",
        estabelecimentos: [
            {
                nome: "ALMAI Instituto de Neurodesenvolvimento Infantil",
                endereco: "Avenida Conselheiro Nébias, 421, Encruzilhada, Santos / SP - CEP 11045-001",
                telefones: ["(13) 99750-6555", "(13) 99808-8850"],
            },
            {
                nome: "Centro Médico Popular Saúde da Família",
                endereco: "Avenida Nossa Senhora de Fátima, 555, Chico de Paula, Santos / SP - CEP 11085-203",
                telefones: ["0800 521-0101"],
            },
            {
                nome: "Centro de Reabilitação Neurológica Matheus Alvares",
                endereco: "Avenida Ana Costa, 146, Vila Matias, Santos / SP - CEP 11060-000",
                telefones: ["(13) 3327-9663", "(13) 97410-7405"],
            },
            {
                nome: "Clínica Terapêutica Bem Estar",
                endereco: "Avenida Afonso Pena, 312, Boqueirão, Santos / SP - CEP 11020-000",
                telefones: ["(13) 99182-0229"],
            },
            {
                nome: "Foncare Santos",
                endereco: "Avenida Ana Costa, 101, Gonzaga, Santos / SP - CEP 11060-001",
                telefones: ["0800 771-2915"],
            },
            {
                nome: "RGD Multiespecialidades Médicas",
                endereco: "Rua Doutor Carvalho de Mendonça, 330, Vila Belmiro, Santos / SP - CEP 11070-101",
                telefones: ["(13) 3224-1331"],
            },
        ],
    },
    {
        especialidade: "Gastroenterologia",
        estabelecimentos: [
            {
                nome: "APAS - Santos",
                endereco: "Rua Carvalho De Mendonça, 335, Vila Belmiro, Santos / SP - CEP 11070-101",
                telefones: ["(13) 3226-5000"],
            },
            {
                nome: "Centro Clínico Frei Galvão - Santos",
                endereco: "Rua Doutor Heitor de Moraes, 15, Boqueirão, Santos / SP - CEP 11045-570",
                telefones: ["(13) 4090-1680"],
            },
            {
                nome: "Centro Médico Popular Saúde da Família",
                endereco: "Avenida Nossa Senhora de Fátima, 555, Chico de Paula, Santos / SP - CEP 11085-203",
                telefones: ["0800 521-0101"],
            },
            {
                nome: "EGO - Consultório Médico",
                endereco: "Rua Barão de Paranapiaçaba, 233, Encruzilhada, Santos / SP - CEP 11050-251",
                telefones: ["(13) 3231-2303"],
            },
            {
                nome: "Santa Casa de Santos",
                endereco: "Rua Doutor Cláudio Luís da Costa, 50, Jabaquara, Santos / SP - CEP 11075-900",
                telefones: ["(13) 3202-0600"],
            },
        ],
    },
    {
        especialidade: "Geriatria",
        estabelecimentos: [
            {
                nome: "Centro Médico Popular Saúde da Família",
                endereco: "Avenida Nossa Senhora de Fátima, 555, Chico de Paula, Santos / SP - CEP 11085-203",
                telefones: ["0800 521-0101"],
            },
        ],
    },
    {
        especialidade: "Ginecologia",
        estabelecimentos: [
            {
                nome: "Centro Clínico Frei Galvão - Santos",
                endereco: "Rua Doutor Heitor de Moraes, 15, Boqueirão, Santos / SP - CEP 11045-570",
                telefones: ["(13) 4090-1680"],
            },
            {
                nome: "Centro Médico Popular Saúde da Família",
                endereco: "Avenida Nossa Senhora de Fátima, 555, Chico de Paula, Santos / SP - CEP 11085-203",
                telefones: ["0800 521-0101"],
            },
            {
                nome: "Clínica Saúde Ideal LTDA",
                endereco: "Avenida Ana Costa, 259, Gonzaga, Santos / SP - CEP 11060-001",
                telefones: ["(13) 3341-4321"],
            },
            {
                nome: "Santa Casa de Santos",
                endereco: "Rua Doutor Cláudio Luís da Costa, 50, Jabaquara, Santos / SP - CEP 11075-900",
                telefones: ["(13) 3202-0600"],
            },
            {
                nome: "Santos Medical Group",
                endereco: "Avenida Rei Alberto I, 243, Ponta da Praia, Santos / SP - CEP 11030-381",
                telefones: ["(13) 3269-0020", "(13) 3877-7337"],
            },
        ],
    },
    {
        especialidade: "Ginecologia e Obstetrícia",
        estabelecimentos: [
            {
                nome: "APAS - Santos",
                endereco: "Rua Carvalho De Mendonça, 335, Vila Belmiro, Santos / SP - CEP 11070-101",
                telefones: ["(13) 3226-5000"],
            },
            {
                nome: "Clínica Médica Ana Costa LTDA",
                endereco: "Avenida Afonso Pena, 314 Boqueirão, Santos / SP - CEP 11020-000",
                telefones: ["(13) 3272-5399", "(13) 99113-3818"],
            },
            {
                nome: "Clínica Saúde Ideal LTDA",
                endereco: "Avenida Ana Costa, 259, Gonzaga, Santos / SP - CEP 11060-001",
                telefones: ["(13) 3341-4321"],
            },
            {
                nome: "EGO - Consultório Médico",
                endereco: "Rua Barão de Paranapiaçaba, 233, Encruzilhada, Santos / SP - CEP 11050-251",
                telefones: ["(13) 3231-2303"],
            },
            {
                nome: "Santa Casa de Santos",
                endereco: "Rua Doutor Cláudio Luís da Costa, 50, Jabaquara, Santos / SP - CEP 11075-900",
                telefones: ["(13) 3202-0600"],
            },
        ],
    },
    {
        especialidade: "Hematologia e Hemoterapia",
        estabelecimentos: [
            {
                nome: "APAS - Santos",
                endereco: "Rua Carvalho De Mendonça, 335, Vila Belmiro, Santos / SP - CEP 11070-101",
                telefones: ["(13) 3226-5000"],
            },
            {
                nome: "Centro Clínico Frei Galvão - Santos",
                endereco: "Rua Doutor Heitor de Moraes, 15, Boqueirão, Santos / SP - CEP 11045-570",
                telefones: ["(13) 4090-1680"],
            },
            {
                nome: "Santa Casa de Santos",
                endereco: "Rua Doutor Cláudio Luís da Costa, 50, Jabaquara, Santos / SP - CEP 11075-900",
                telefones: ["(13) 3202-0600"],
            },
            {
                nome: "Santos Medical Group",
                endereco: "Avenida Rei Alberto I, 243, Ponta da Praia, Santos / SP - CEP 11030-381",
                telefones: ["(13) 3269-0020", "(13) 3877-7337"],
            },
        ],
    },
    {
        especialidade: "Hepatologia",
        estabelecimentos: [
            {
                nome: "APAS - Santos",
                endereco: "Rua Carvalho De Mendonça, 335, Vila Belmiro, Santos / SP - CEP 11070-101",
                telefones: ["(13) 3226-5000"],
            },
            {
                nome: "Santa Casa de Santos",
                endereco: "Rua Doutor Cláudio Luís da Costa, 50, Jabaquara, Santos / SP - CEP 11075-900",
                telefones: ["(13) 3202-0600"],
            },
        ],
    },
    {
        especialidade: "Infectologia",
        estabelecimentos: [
            {
                nome: "APAS - Santos",
                endereco: "Rua Carvalho De Mendonça, 335, Vila Belmiro, Santos / SP - CEP 11070-101",
                telefones: ["(13) 3226-5000"],
            },
            {
                nome: "Centro Clínico Frei Galvão - Santos",
                endereco: "Rua Doutor Heitor de Moraes, 15, Boqueirão, Santos / SP - CEP 11045-570",
                telefones: ["(13) 4090-1680"],
            },
        ],
    },
    {
        especialidade: "Mamografia",
        estabelecimentos: [
            {
                nome: "Brasil Imagem Medicinadiagnóstica",
                endereco: "Avenida Ana Costa, 259, Gonzaga, Santos / SP - CEP 11060-001",
                telefones: ["(13) 3235-7658", "(13) 3235-7805"],
            },
            {
                nome: "Clínica Radiológica de Santos",
                endereco: "Avenida Conselheiro Nébias, 521, Santos / SP - CEP 11045-001",
                telefones: ["(13) 3228-2525"],
            },
            {
                nome: "Mega Imagem",
                endereco: "Rua Mato Grosso, 79, Boqueirão, Santos / SP - CEP 11055-010",
                telefones: ["(13) 3202-1990"],
            },
            {
                nome: "Vila Rica Clínica de Imagem",
                endereco: "Avenida Doutor Bernardino de Campos, 16, Vila Belmiro, Santos / SP - CEP 11065-000",
                telefones: ["(13) 3257-2300"],
            },
        ],
    },
    {
        especialidade: "Mastologia",
        estabelecimentos: [
            {
                nome: "APAS - Santos",
                endereco: "Rua Carvalho De Mendonça, 335, Vila Belmiro, Santos / SP - CEP 11070-101",
                telefones: ["(13) 3226-5000"],
            },
            {
                nome: "Centro Clínico Frei Galvão - Santos",
                endereco: "Rua Doutor Heitor de Moraes, 15, Boqueirão, Santos / SP - CEP 11045-570",
                telefones: ["(13) 4090-1680"],
            },
            {
                nome: "Clínica Quiron",
                endereco: "Avenida Senador Feijó, 686, Vila Matias, Santos / SP - CEP 11015-504",
                telefones: ["(13) 3321-7022"],
            },
            {
                nome: "Santa Casa de Santos",
                endereco: "Rua Doutor Cláudio Luís da Costa, 50, Jabaquara, Santos / SP - CEP 11075-900",
                telefones: ["(13) 3202-0600"],
            },
        ],
    },
    {
        especialidade: "Medicina Laboratorial",
        estabelecimentos: [
            {
                nome: "APAS - Santos",
                endereco: "Rua Carvalho De Mendonça, 335, Vila Belmiro, Santos / SP - CEP 11070-101",
                telefones: ["(13) 3226-5000"],
            },
            {
                nome: "Biolab Laboratório de Analclin",
                endereco: "Avenida Ana Costa, 259, Encruzilhada, Santos / SP - CEP 11060-907",
                telefones: ["(13) 3251-8080"],
            },
            {
                nome: "Biolab Santos",
                endereco: "Avenida Afonso Pena, 314, Boqueirão, Santos / SP - CEP 11020-000",
                telefones: ["(13) 3301-1832"],
            },
            {
                nome: "Eurofns Clinical Diagnostics",
                endereco: "Avenida Ana Costa, 424, Gonzaga, Santos / SP - CEP 11060-002",
                telefones: ["(13) 2127-7000"],
            },
            {
                nome: "Laboratório Clínico Santa Clara",
                endereco: "Avenida Ana Costa, 165, Vila Matias, Santos / SP - CEP 11060-001",
                telefones: ["(13) 3221-8124", "(13) 99183-0475"],
            },
            {
                nome: "Laboratório Gonzaga",
                endereco: "Avenida Ana Costa, 493, Gonzaga, Santos / SP - CEP 11060-003",
                telefones: ["(13) 3222-6151", "(13) 3284-0122", "(13) 99771-1230"],
            },
        ],
    },
    {
        especialidade: "Medicina Nuclear",
        estabelecimentos: [
            {
                nome: "Medicina Hiperbárica de Santos",
                endereco: "Avenida Ana Costa, 193, Gonzaga, Santos / SP - CEP 11060-001",
                telefones: ["(13) 3221-8081"],
            },
        ],
    },
    {
        especialidade: "Musicoterapia",
        estabelecimentos: [
            {
                nome: "ALMAI Instituto de Neurodesenvolvimento Infantil",
                endereco: "Avenida Doutor Pedro Lessa, 2797, Embaré, Santos / SP - CEP 11025-015",
                telefones: ["(13) 3227-2200", "(13) 99690-9009"],
            },
            {
                nome: "ALMAI Instituto de Neurodesenvolvimento Infantil",
                endereco: "Avenida Conselheiro Nébias, 421, Encruzilhada, Santos / SP - CEP 11045-001",
                telefones: ["(13) 99750-6555", "(13) 99808-8850"],
            },
            {
                nome: "Centro de Reabilitação Neurológica Matheus Alvares",
                endereco: "Avenida Ana Costa, 146, Vila Matias, Santos / SP - CEP 11060-000",
                telefones: ["(13) 3327-9663", "(13) 97410-7405"],
            },
            {
                nome: "Foncare Santos",
                endereco: "Avenida Ana Costa, 101, Gonzaga, Santos / SP - CEP 11060-001",
                telefones: ["0800 771-2915"],
            },
        ],
    },
    {
        especialidade: "Nefrologia",
        estabelecimentos: [
            {
                nome: "APAS - Santos",
                endereco: "Rua Carvalho De Mendonça, 335, Vila Belmiro, Santos / SP - CEP 11070-101",
                telefones: ["(13) 3226-5000"],
            },
            {
                nome: "Centro Clínico Frei Galvão - Santos",
                endereco: "Rua Doutor Heitor de Moraes, 15, Boqueirão, Santos / SP - CEP 11045-570",
                telefones: ["(13) 4090-1680"],
            },
            {
                nome: "DaVita Santos",
                endereco: "Avenida Doutor Bernardino de Campos, 47, Vila Belmiro, Santos / SP - CEP 11065-001",
                telefones: ["(13) 4003-6246"],
            },
            {
                nome: "Santos Medical Group",
                endereco: "Avenida Rei Alberto I, 243, Ponta da Praia, Santos / SP - CEP 11030-381",
                telefones: ["(13) 3269-0020", "(13) 3877-7337"],
            },
        ],
    },
    {
        especialidade: "Neurocirurgia",
        estabelecimentos: [
            {
                nome: "APAS - Santos",
                endereco: "Rua Carvalho De Mendonça, 335, Vila Belmiro, Santos / SP - CEP 11070-101",
                telefones: ["(13) 3226-5000"],
            },
            {
                nome: "Centro Clínico Frei Galvão - Santos",
                endereco: "Rua Doutor Heitor de Moraes, 15, Boqueirão, Santos / SP - CEP 11045-570",
                telefones: ["(13) 4090-1680"],
            },
            {
                nome: "Santa Casa de Santos",
                endereco: "Rua Doutor Cláudio Luís da Costa, 50, Jabaquara, Santos / SP - CEP 11075-900",
                telefones: ["(13) 3202-0600"],
            },
        ],
    },
    {
        especialidade: "Neurologia",
        estabelecimentos: [
            {
                nome: "APAS - Santos",
                endereco: "Rua Carvalho De Mendonça, 335, Vila Belmiro, Santos / SP - CEP 11070-101",
                telefones: ["(13) 3226-5000"],
            },
            {
                nome: "ALMAI Instituto de Neurodesenvolvimento Infantil",
                endereco: "Avenida Doutor Pedro Lessa, 2797, Embaré, Santos / SP - CEP 11025-015",
                telefones: ["(13) 3227-2200", "(13) 99690-9009"],
            },
            {
                nome: "Santa Casa de Santos",
                endereco: "Rua Doutor Cláudio Luís da Costa, 50, Jabaquara, Santos / SP - CEP 11075-900",
                telefones: ["(13) 3202-0600"],
            },
            {
                nome: "Santos Medical Group",
                endereco: "Avenida Rei Alberto I, 243, Ponta da Praia, Santos / SP - CEP 11030-381",
                telefones: ["(13) 3269-0020", "(13) 3877-7337"],
            },
        ],
    },

    {
        especialidade: "Neurologia Pediátrica",
        estabelecimentos: [
            {
                nome: "ALMAI Instituto de Neurodesenvolvimento Infantil",
                endereco: "Avenida Conselheiro Nébias, 421, Encruzilhada, Santos / SP - CEP 11045-001",
                telefones: ["(13) 99750-6555", "(13) 99808-8850"],
            },
        ],
    },
    {
        especialidade: "Neuropsicologia",
        estabelecimentos: [
            {
                nome: "ALMAI Instituto de Neurodesenvolvimento Infantil",
                endereco: "Avenida Doutor Pedro Lessa, 2797, Embaré, Santos / SP - CEP 11025-015",
                telefones: ["(13) 3227-2200", "(13) 99690-9009"],
            },
            {
                nome: "ALMAI Instituto de Neurodesenvolvimento Infantil",
                endereco: "Avenida Conselheiro Nébias, 421, Encruzilhada, Santos / SP - CEP 11045-001",
                telefones: ["(13) 99750-6555", "(13) 99808-8850"],
            },
        ],
    },
    {
        especialidade: "Nutrição",
        estabelecimentos: [
            {
                nome: "APAS - Santos",
                endereco: "Rua Carvalho De Mendonça, 335, Vila Belmiro, Santos / SP - CEP 11070-101",
                telefones: ["(13) 3226-5000"],
            },
            {
                nome: "Centro Clínico Frei Galvão - Santos",
                endereco: "Rua Doutor Heitor de Moraes, 15, Boqueirão, Santos / SP - CEP 11045-570",
                telefones: ["(13) 4090-1680"],
            },
            {
                nome: "Centro Médico Popular Saúde da Família - Santos",
                endereco: "Avenida Nossa Senhora de Fátima, 555, Chico de Paula, Santos / SP - CEP 11085-203",
                telefones: ["0800 521-0101"],
            },
            {
                nome: "Clinica Terapeutica Bem Estar",
                endereco: "Avenida Afonso Pena, 312, Boqueirão, Santos / SP - CEP 11020-000",
                telefones: ["(13) 99182-0229"],
            },
            {
                nome: "RGD Multiespecialidades Médicas",
                endereco: "Rua Doutor Carvalho de Mendonça, 330, Vila Belmiro, Santos / SP - CEP 11070-101",
                telefones: ["(13) 3224-1331"],
            },
        ],
    },
    {
        especialidade: "Odontogeriatria",
        estabelecimentos: [
            {
                nome: "Belato Mendes Odontologia Ltda",
                endereco: "Rua Goiás, 225, Gonzaga, Santos / SP - CEP 11050-101",
                telefones: [],
            },
        ]
    },
    {
        especialidade: "Odontologia",
        estabelecimentos: [
            {
                nome: "Ana Paula Tavares",
                endereco: "Avenida Afonso Pena, 167, Embare, Santos / SP - CEP 11020-001",
                telefones: ["(13) 3233-9829", "(13) 99749-5015"],
            },
            {
                nome: "Camila Werner Kozseran",
                endereco: "Rua Alexandre Martins, 71, Aparecida, Santos / SP - CEP 11025-201",
                telefones: ["(13) 99140-3773"],
            },
            {
                nome: "Centro Santista de Odontologia",
                endereco: "Avenida Pedro Lessa, 1920, Aparecida, Santos / SP - CEP 11025-002",
                telefones: [],
            },
            {
                nome: "Debora Ferreira de Cicco",
                endereco: "Avenida Conselheiro Nebias, 754, Boqueirão, Santos / SP - CEP 11045-770",
                telefones: ["(13) 3223-8133", "(13) 99153-2623"],
            },
            {
                nome: "Doutor Dentista Santos Clinica Odontológica",
                endereco: "Rua Delphin Moreira, 389, Embaré, Santos / SP - CEP 11040-101",
                telefones: ["(13) 3223-5154"],
            },
            {
                nome: "Eduardo Jamar de Queiroz",
                endereco: "Rua Goitacazes, 06, Gonzaga, Santos / SP - CEP 11055-210",
                telefones: ["(13) 3284-4325"],
            },
            {
                nome: "Kellen Cristina de Oliveira",
                endereco: "Avenida Santista, 541, Morro de Nova Cintra, Santos / SP - CEP 11080-000",
                telefones: ["(13) 3025-7058", "(13) 99678-1306"],
            },
            {
                nome: "Ortopraia",
                endereco: "Rua Professor Pirajá da Silva, 149, Aparecida, Santos / SP - CEP 11035-200",
                telefones: ["(13) 3227-7088", "(13) 97403-0391"],
            },
        ],
    },
    {
        especialidade: "Odontopediatria",
        estabelecimentos: [
            {
                nome: "Alessandra Scurti de Almeida Lima",
                endereco: "Rua Fernão Dias, 12, Gonzaga, Santos / SP - CEP 11055-220",
                telefones: ["(13) 99683-5783"],
            },
            {
                nome: "Ana Carolina Tavares da Silva",
                endereco: "Avenida Afonso Pena, 167, Boqueirão, Santos / SP - CEP 11020-000",
                telefones: ["(13) 3233-9829"],
            },
            {
                nome: "Karine Fausto Gonzalez",
                endereco: "Avenida Marechal Deodoro, 532, Gonzaga, Santos / SP - CEP 11060-400",
                telefones: ["(13) 99730-9095"],
            },
            {
                nome: "Kellen Cristina de Oliveira",
                endereco: "Avenida Santista, 541, Morro de Nova Cintra, Santos / SP - CEP 11080-000",
                telefones: ["(13) 3025-7058", "(13) 99678-1306"],
            },
            {
                nome: "Ortopraia",
                endereco: "Rua Professor Pirajá da Silva, 149, Aparecida, Santos / SP - CEP 11035-200",
                telefones: ["(13) 3227-7088", "(13) 97403-0391"],
            },
        ],
    },
    {
        especialidade: "Oftalmologia",
        estabelecimentos: [
            {
                nome: "Centro Médico Popular Saúde da Família - Santos",
                endereco: "Avenida Nossa Senhora de Fátima, 555, Chico de Paula, Santos / SP - CEP 11085-203",
                telefones: ["0800 521-0101"],
            },
            {
                nome: "Clínica Saúde Ideal LTDA",
                endereco: "Avenida Ana Costa, 259, Gonzaga, Santos / SP - CEP 11060-001",
                telefones: ["(13) 3341-4321"],
            },
            {
                nome: "Clínica de Olhos Cid Laser",
                endereco: "Rua Oswaldo Cochrane, 192, Embaré, Santos / SP - CEP 11040-110",
                telefones: ["(13) 3227-4475", "(13) 3271-1125"],
            },
            {
                nome: "FP - Clínica Oftalmológica LTDA",
                endereco: "Rua Júlio Conceição, 216, Vila Matias, Santos / SP - CEP 11015-540",
                telefones: ["(13) 3222-5590"],
            },
            {
                nome: "Piccinini Oftamologia",
                endereco: "Avenida Washington Luís, 316, Gonzaga, Santos / SP - CEP 11055-000",
                telefones: ["(13) 3019-2238", "(13) 3225-2262"],
            },
            {
                nome: "Santa Casa de Santos",
                endereco: "Rua Doutor Cláudio Luís da Costa, 50, Jabaquara, Santos / SP - CEP 11075-900",
                telefones: ["(13) 3202-0600"],
            },
        ],
    },
    {
        especialidade: "Oncologia Clínica",
        estabelecimentos: [
            {
                nome: "APAS - Santos",
                endereco: "Rua Carvalho De Mendonça, 335, Vila Belmiro, Santos / SP - CEP 11070-101",
                telefones: ["(13) 3226-5000"],
            },
        ],
    },
    {
        especialidade: "Ortodontia",
        estabelecimentos: [
            {
                nome: "Ana Paula Tavares",
                endereco: "Avenida Afonso Pena, 167, Embare, Santos / SP - CEP 11020-001",
                telefones: ["(13) 3233-9829", "(13) 99749-5015"],
            },
            {
                nome: "Belato Mendes Odontologia Ltda",
                endereco: "Rua Goiás, 225, Gonzaga, Santos / SP - CEP 11050-101",
                telefones: [""],
            },
            {
                nome: "Denise Moraes dos Santos",
                endereco: "Avenida Senador Pinheiro Machado, 589, Marapé, Santos / SP - CEP 11075-001",
                telefones: ["(13) 3237-3368"],
            },
            {
                nome: "Gabrielle Segui Marcelino dos Santos",
                endereco: "Avenida Conselheiro Nébias, 703, Boqueirão, Santos / SP - CEP 11045-003",
                telefones: ["(13) 98106-0250"],
            },
            {
                nome: "Maria Aparecida Pace",
                endereco: "Rua Alexandre Martins, 143, Aparecida, Santos / SP - CEP 11025-201",
                telefones: ["(13) 3238-7736", "(13) 3469-7557"],
            },
            {
                nome: "Orthodontic",
                endereco: "Avenida Ana Costa, 465, Gonzaga, Santos / SP - CEP 11060-003",
                telefones: ["(13) 3289-8088", "(13) 99614-1255"],
            },
            {
                nome: "Ortopraia",
                endereco: "Rua Professor Pirajá da Silva, 149, Aparecida, Santos / SP - CEP 11035-200",
                telefones: ["(13) 3227-7088", "(13) 97403-0391"],
            },
            {
                nome: "Sandrine Nicola Mathias de Oliveira",
                endereco: "Rua Ministro João Mendes, 11040, Embaré, Santos / SP - CEP 11040-260",
                telefones: ["(19) 98183-0505", "(19) 99136-6488"],
            },
        ],
    },
    {
        especialidade: "Ortopedia e Traumatologia",
        estabelecimentos: [
            {
                nome: "APAS - Santos",
                endereco: "Rua Carvalho De Mendonça, 335, Vila Belmiro, Santos / SP - CEP 11070-101",
                telefones: ["(13) 3226-5000"],
            },
            {
                nome: "Centro Clínico Frei Galvão - Santos",
                endereco: "Rua Doutor Heitor de Moraes, 15, Boqueirão, Santos / SP - CEP 11045-570",
                telefones: ["(13) 4090-1680"],
            },
            {
                nome: "Centro Médico Popular Saúde da Família - Santos",
                endereco: "Avenida Nossa Senhora de Fátima, 555, Chico de Paula, Santos / SP - CEP 11085-203",
                telefones: ["0800 521-0101"],
            },
            {
                nome: "Clínica Saúde Ideal LTDA",
                endereco: "Avenida Ana Costa, 259, Gonzaga, Santos / SP - CEP 11060-001",
                telefones: ["(13) 3341-4321"],
            },
            {
                nome: "Hospital Frei Galvão - Santos",
                endereco: "Rua Doutor Heitor de Moraes, 19, Boqueirão, Santos / SP - CEP 11045-570",
                telefones: ["(13) 3229-1500", "(11) 4090-1680"],
            },
            {
                nome: "Instituto Ortopédico Itararé - Santos",
                endereco: "Avenida Conselheiro Nébias, 730, Boqueirão, Santos / SP - CEP 11045-002",
                telefones: ["(13) 3468-1550"],
            },
            {
                nome: "Medicina do Trauma e Reabilitação Santista",
                endereco: "Rua Alexandre Herculano, 204, Gonzaga, Santos / SP - CEP 11050-031",
                telefones: ["(13) 3234-2349", "(13) 3234-2441"],
            },
            {
                nome: "Santa Casa de Santos",
                endereco: "Rua Doutor Cláudio Luís da Costa, 50, Jabaquara, Santos / SP - CEP 11075-900",
                telefones: ["(13) 3202-0600"],
            },
            {
                nome: "Vitta Med",
                endereco: "Rua Júlio Conceição, 296, Vila Matias, Santos / SP - CEP 11015-540",
                telefones: ["(13) 4141-4774"],
            },
        ],
    },
    {
        especialidade: "Ortopedia Geral",
        estabelecimentos: [
            {
                nome: "J.P.M Serviços Médicos Ltda",
                endereco: "Avenida Conselheiro Nébias, 730, Boqueirão, Santos / SP - CEP 11045-002",
                telefones: ["(13) 3468-1550"],
            },
            {
                nome: "Santos Medical Group",
                endereco: "Avenida Rei Alberto I, 243, Ponta da Praia, Santos / SP - CEP 11030-381",
                telefones: ["(13) 3269-0020", "(13) 3877-7337"],
            },
        ],
    },
    {
        especialidade: "Ortopedia Joelho",
        estabelecimentos: [
            {
                nome: "APAS - Santos",
                endereco: "Rua Carvalho De Mendonça, 335, Vila Belmiro, Santos / SP - CEP 11070-101",
                telefones: ["(13) 3226-5000"],
            },
            {
                nome: "Centro Clínico Frei Galvão - Santos",
                endereco: "Rua Doutor Heitor de Moraes, 15, Boqueirão, Santos / SP - CEP 11045-570",
                telefones: ["(13) 4090-1680"],
            },
            {
                nome: "Instituto Ortopédico Itararé - Santos",
                endereco: "Avenida Conselheiro Nébias, 730, Boqueirão, Santos / SP - CEP 11045-002",
                telefones: ["(13) 3468-1550"],
            },
            {
                nome: "Medicina do Trauma e Reabilitação Santista",
                endereco: "Rua Alexandre Herculano, 204, Gonzaga, Santos / SP - CEP 11050-031",
                telefones: ["(13) 3234-2349", "(13) 3234-2441"],
            },
        ],
    },
    {
        especialidade: "Ortopedia Mão e Punho",
        estabelecimentos: [
            {
                nome: "APAS - Santos",
                endereco: "Rua Carvalho De Mendonça, 335, Vila Belmiro, Santos / SP - CEP 11070-101",
                telefones: ["(13) 3226-5000"],
            },
            {
                nome: "Centro Clínico Frei Galvão - Santos",
                endereco: "Rua Doutor Heitor de Moraes, 15, Boqueirão, Santos / SP - CEP 11045-570",
                telefones: ["(13) 4090-1680"],
            },
            {
                nome: "Instituto Ortopédico Itararé - Santos",
                endereco: "Avenida Conselheiro Nébias, 730, Boqueirão, Santos / SP - CEP 11045-002",
                telefones: ["(13) 3468-1550"],
            },
            {
                nome: "Medicina do Trauma e Reabilitação Santista",
                endereco: "Rua Alexandre Herculano, 204, Gonzaga, Santos / SP - CEP 11050-031",
                telefones: ["(13) 3234-2349", "(13) 3234-2441"],
            },
        ],
    },
    {
        especialidade: "Ortopedia Ombro e Cotovelo",
        estabelecimentos: [
            {
                nome: "APAS - Santos",
                endereco: "Rua Carvalho De Mendonça, 335, Vila Belmiro, Santos / SP - CEP 11070-101",
                telefones: ["(13) 3226-5000"],
            },
            {
                nome: "Centro Clínico Frei Galvão - Santos",
                endereco: "Rua Doutor Heitor de Moraes, 15, Boqueirão, Santos / SP - CEP 11045-570",
                telefones: ["(13) 4090-1680"],
            },
            {
                nome: "Instituto Ortopédico Itararé - Santos",
                endereco: "Avenida Conselheiro Nébias, 730, Boqueirão, Santos / SP - CEP 11045-002",
                telefones: ["(13) 3468-1550"],
            },
        ],
    },
    {
        especialidade: "Ortopedia Pé e Tornozelo",
        estabelecimentos: [
            {
                nome: "APAS - Santos",
                endereco: "Rua Carvalho De Mendonça, 335, Vila Belmiro, Santos / SP - CEP 11070-101",
                telefones: ["(13) 3226-5000"],
            },
            {
                nome: "Centro Clínico Frei Galvão - Santos",
                endereco: "Rua Doutor Heitor de Moraes, 15, Boqueirão, Santos / SP - CEP 11045-570",
                telefones: ["(13) 4090-1680"],
            },
        ],
    },
    {
        especialidade: "Ortopedia Quadril",
        estabelecimentos: [
            {
                nome: "APAS - Santos",
                endereco: "Rua Carvalho De Mendonça, 335, Vila Belmiro, Santos / SP - CEP 11070-101",
                telefones: ["(13) 3226-5000"],
            },
            {
                nome: "Centro Clínico Frei Galvão - Santos",
                endereco: "Rua Doutor Heitor de Moraes, 15, Boqueirão, Santos / SP - CEP 11045-570",
                telefones: ["(13) 4090-1680"],
            },
            {
                nome: "Instituto Ortopédico Itararé - Santos",
                endereco: "Avenida Conselheiro Nébias, 730, Boqueirão, Santos / SP - CEP 11045-002",
                telefones: ["(13) 3468-1550"],
            },
            {
                nome: "Medicina do Trauma e Reabilitação Santista",
                endereco: "Rua Alexandre Herculano, 204, Gonzaga, Santos / SP - CEP 11050-031",
                telefones: ["(13) 3234-2349", "(13) 3234-2441"],
            },
        ],
    },
    {
        especialidade: "Otorrinolaringologia",
        estabelecimentos: [
            {
                nome: "Centro Clínico Frei Galvão - Santos",
                endereco: "Rua Doutor Heitor de Moraes, 15, Boqueirão, Santos / SP - CEP 11045-570",
                telefones: ["(13) 4090-1680"],
            },
            {
                nome: "Centro Médico Popular Saúde da Família - Santos",
                endereco: "Avenida Nossa Senhora de Fátima, 555, Chico de Paula, Santos / SP - CEP 11085-203",
                telefones: ["0800 521-0101"],
            },
            {
                nome: "Santa Casa de Santos",
                endereco: "Rua Doutor Cláudio Luís da Costa, 50, Jabaquara, Santos / SP - CEP 11075-900",
                telefones: ["(13) 3202-0600"],
            },
        ],
    },
    {
        especialidade: "Pediatria",
        estabelecimentos: [
            {
                nome: "Centro Clínico Frei Galvão - Santos",
                endereco: "Rua Doutor Heitor de Moraes, 15, Boqueirão, Santos / SP - CEP 11045-570",
                telefones: ["(13) 4090-1680"],
            },
            {
                nome: "Centro Médico Popular Saúde da Família - Santos",
                endereco: "Avenida Nossa Senhora de Fátima, 555, Chico de Paula, Santos / SP - CEP 11085-203",
                telefones: ["0800 521-0101"],
            },
            {
                nome: "Hospital Frei Galvão - Santos",
                endereco: "Rua Doutor Heitor de Moraes, 19, Boqueirão, Santos / SP - CEP 11045-570",
                telefones: ["(13) 3229-1500", "(11) 4090-1680"],
            },
            {
                nome: "Santa Casa de Santos",
                endereco: "Rua Doutor Cláudio Luís da Costa, 50, Jabaquara, Santos / SP - CEP 11075-900",
                telefones: ["(13) 3202-0600"],
            },
        ],
    },
    {
        especialidade: "Periodontia",
        estabelecimentos: [
            {
                nome: "Fabiana Saloio Machado Lopes",
                endereco: "Rua Doutor Tolentino Filgueiras, 119, Gonzaga, Santos / SP - CEP 11060-471",
                telefones: ["(13) 3877-5803", "(13) 99118-1499", "(13) 99163-1978"],
            },
        ],
    },
    {
        especialidade: "Pneumologia",
        estabelecimentos: [
            {
                nome: "Dr. Marcelo Mota Monteiro",
                endereco: "Rua Firmino Barbosa, 155, Boqueirão, Santos / SP - CEP 11055-130",
                telefones: ["(13) 3224-5964"],
            },
            {
                nome: "Santa Casa de Santos",
                endereco: "Rua Doutor Cláudio Luís da Costa, 50, Jabaquara, Santos / SP - CEP 11075-900",
                telefones: ["(13) 3202-0600"],
            },
        ],
    },
    {
        especialidade: "Prótese Dentária",
        estabelecimentos: [
            {
                nome: "Eduardo Jamar de Queiroz",
                endereco: "Rua Goitacazes, 06, Gonzaga, Santos / SP - CEP 11055-210",
                telefones: ["(13) 3284-4325"],
            },
            {
                nome: "Fabiana Saloio Machado Lopes",
                endereco: "Rua Doutor Tolentino Filgueiras, 119, Gonzaga, Santos / SP - CEP 11060-471",
                telefones: ["(13) 3877-5803", "(13) 99118-1499", "(13) 99163-1978"],
            },
            {
                nome: "Gabrielle Segui Marcelino dos Santos",
                endereco: "Avenida Conselheiro Nébias, 703, Boqueirão, Santos / SP - CEP 11045-003",
                telefones: ["(13) 98106-0250"],
            },
            {
                nome: "Implafacil Clinic",
                endereco: "Avenida Marechal Floriano Peixoto, 44, Gonzaga, Santos / SP - CEP 11060-910",
                telefones: ["(13) 3284-4325"],
            },
            {
                nome: "MDF Odontologia Ltda",
                endereco: "Rua Oswaldo Cochrane, 48, Embaré, Santos / SP - CEP 11040-110",
                telefones: ["(13) 97418-7518"],
            },
            {
                nome: "Patricia Sakamoto",
                endereco: "Avenida Pedro Lessa, 3076, Embare, Santos / SP - CEP 11050-002",
                telefones: ["(13) 3014-3181", "(13) 99708-1835"],
            },
            {
                nome: "Solange Maria Mazza",
                endereco: "Avenida Ana Costa, 521, Gonzaga, Santos / SP - CEP 11060-003",
                telefones: ["(13) 3877-7541", "(13) 98223-1687"],
            },
        ],
    },
    {
        especialidade: "Psicologia",
        estabelecimentos: [
            {
                nome: "APAS - Santos",
                endereco: "Rua Carvalho De Mendonça, 335, Vila Belmiro, Santos / SP - CEP 11070-101",
                telefones: ["(13) 3226-5000"],
            },
            {
                nome: "ALMAI Instituto de Neurodesenvolvimento Infantil",
                endereco: "Avenida Doutor Pedro Lessa, 2797, Embaré, Santos / SP - CEP 11025-015",
                telefones: ["(13) 3227-2200", "(13) 99690-9009"],
            },
            {
                nome: "ALMAI Instituto de Neurodesenvolvimento Infantil",
                endereco: "Avenida Conselheiro Nébias, 421, Encruzilhada, Santos / SP - CEP 11045-001",
                telefones: ["(13) 99750-6555", "(13) 99808-8850"],
            },
            {
                nome: "Centro Médico Popular Saúde da Família - Santos",
                endereco: "Avenida Nossa Senhora de Fátima, 555, Chico de Paula, Santos / SP - CEP 11085-203",
                telefones: ["0800 521-0101"],
            },
            {
                nome: "Centro de Reabilitação Neurológica Matheus Alvares - Santos",
                endereco: "Avenida Ana Costa, 146, Vila Matias, Santos / SP - CEP 11060-000",
                telefones: ["(13) 3327-9663", "(13) 97410-7405"],
            },
            {
                nome: "Clínica Terapeutica Bem Estar",
                endereco: "Avenida Afonso Pena, 312, Boqueirão, Santos / SP - CEP 11020-000",
                telefones: ["(13) 99182-0229"],
            },
            {
                nome: "Foncare Santos",
                endereco: "Avenida Ana Costa, 101, Gonzaga, Santos / SP - CEP 11060-001",
                telefones: ["0800 771-2915"],
            },
            {
                nome: "RGD Multiespecialidades Médicas",
                endereco: "Rua Doutor Carvalho de Mendonça, 330, Vila Belmiro, Santos / SP - CEP 11070-101",
                telefones: ["(13) 3224-1331"],
            },
            {
                nome: "Renata Nascimento da Conceição",
                endereco: "Avenida Conselheiro Nébias, 688, Boqueirão, Santos / SP - CEP 11045-002",
                telefones: ["(13) 98853-9647"],
            },
        ],
    },
    {
        especialidade: "Psicomotricidade",
        estabelecimentos: [
            {
                nome: "Centro de Reabilitação Neurológica Matheus Alvares - Santos",
                endereco: "Avenida Ana Costa, 146, Vila Matias, Santos / SP - CEP 11060-000",
                telefones: ["(13) 3327-9663", "(13) 97410-7405"],
            },
        ],
    },
    {
        especialidade: "Psicopedagogia",
        estabelecimentos: [
            {
                nome: "Centro de Reabilitação Neurológica Matheus Alvares - Santos",
                endereco: "Avenida Ana Costa, 146, Vila Matias, Santos / SP - CEP 11060-000",
                telefones: ["(13) 3327-9663", "(13) 97410-7405"],
            },
            {
                nome: "Foncare Santos",
                endereco: "Avenida Ana Costa, 101, Gonzaga, Santos / SP - CEP 11060-001",
                telefones: ["0800 771-2915"],
            },
            {
                nome: "RGD Multiespecialidades Médicas",
                endereco: "Rua Doutor Carvalho de Mendonça, 330, Vila Belmiro, Santos / SP - CEP 11070-101",
                telefones: ["(13) 3224-1331"],
            },
        ],
    },
    {
        especialidade: "Psiquiatria",
        estabelecimentos: [
            {
                nome: "APAS - Santos",
                endereco: "Rua Carvalho De Mendonça, 335, Vila Belmiro, Santos / SP - CEP 11070-101",
                telefones: ["(13) 3226-5000"],
            },
            {
                nome: "Centro Clínico Frei Galvão - Santos",
                endereco: "Rua Doutor Heitor de Moraes, 15, Boqueirão, Santos / SP - CEP 11045-570",
                telefones: ["(13) 4090-1680"],
            },
            {
                nome: "Centro Médico Popular Saúde da Família - Santos",
                endereco: "Avenida Nossa Senhora de Fátima, 555, Chico de Paula, Santos / SP - CEP 11085-203",
                telefones: ["0800 521-0101"],
            },
            {
                nome: "RGD Multiespecialidades Médicas",
                endereco: "Rua Doutor Carvalho de Mendonça, 330, Vila Belmiro, Santos / SP - CEP 11070-101",
                telefones: ["(13) 3224-1331"],
            },
        ],
    },
    {
        especialidade: "Psiquiatria da Infância e da Adolescência",
        estabelecimentos: [
            {
                nome: "ALMAI Instituto de Neurodesenvolvimento Infantil",
                endereco: "Avenida Doutor Pedro Lessa, 2797, Embaré, Santos / SP - CEP 11025-015",
                telefones: ["(13) 3227-2200", "(13) 99690-9009"],
            },
            {
                nome: "ALMAI Instituto de Neurodesenvolvimento Infantil",
                endereco: "Avenida Conselheiro Nébias, 421, Encruzilhada, Santos / SP - CEP 11045-001",
                telefones: ["(13) 99750-6555", "(13) 99808-8850"],
            },
        ],
    },
    {
        especialidade: "Ressonância Magnética",
        estabelecimentos: [
            {
                nome: "Clinica Mult Imagem",
                endereco: "Rua Júlio Conceição, 296, Vila Matias, Santos / SP - CEP 11015-540",
                telefones: ["(13) 3202-1250"],
            },
            {
                nome: "Clínica Radiológica de Santos",
                endereco: "Avenida Conselheiro Nébias, 521, Santos / SP - CEP 11045-001",
                telefones: ["(13) 3228-2525"],
            },
            {
                nome: "Mega Imagem",
                endereco: "Rua Mato Grosso, 79, Boqueirão, Santos / SP - CEP 11055-010",
                telefones: ["(13) 3202-1990"],
            },
            {
                nome: "Pasteur Medicina Diagnóstica",
                endereco: "Avenida Conselheiro Nébias, 741, Boqueirão, Santos / SP - CEP 11045-003",
                telefones: ["(13) 2127-7000", "(13) 3394-0500", "(13) 99182-2237"],
            },
            {
                nome: "Vila Rica Clínica de Imagem",
                endereco: "Avenida Doutor Bernardino de Campos, 16, Vila Belmiro, Santos / SP - CEP 11065-000",
                telefones: ["(13) 3257-2300"],
            },
        ],
    },
    {
        especialidade: "Reumatologia",
        estabelecimentos: [
            {
                nome: "Centro Médico Popular Saúde da Família - Santos",
                endereco: "Avenida Nossa Senhora de Fátima, 555, Chico de Paula, Santos / SP - CEP 11085-203",
                telefones: ["0800 521-0101"],
            },
        ],
    },
    {
        especialidade: "RX",
        estabelecimentos: [
            {
                nome: "Clinica Mult Imagem",
                endereco: "Rua Júlio Conceição, 296, Vila Matias, Santos / SP - CEP 11015-540",
                telefones: ["(13) 3202-1250"],
            },
            {
                nome: "Clínica Radiológica de Santos",
                endereco: "Avenida Conselheiro Nébias, 521, Santos / SP - CEP 11045-001",
                telefones: ["(13) 3228-2525"],
            },
            {
                nome: "Medicina do Trauma e Reabilitação Santista",
                endereco: "Rua Alexandre Herculano, 204, Gonzaga, Santos / SP - CEP 11050-031",
                telefones: ["(13) 3234-2349", "(13) 3234-2441"],
            },
            {
                nome: "Mega Imagem",
                endereco: "Rua Mato Grosso, 79, Boqueirão, Santos / SP - CEP 11055-010",
                telefones: ["(13) 3202-1990"],
            },
            {
                nome: "Vila Rica Clínica de Imagem",
                endereco: "Avenida Doutor Bernardino de Campos, 16, Vila Belmiro, Santos / SP - CEP 11065-000",
                telefones: ["(13) 3257-2300"],
            },
        ],
    },
    {
        especialidade: "Terapia Ocupacional",
        estabelecimentos: [
            {
                nome: "ALMAI Instituto de Neurodesenvolvimento Infantil",
                endereco: "Avenida Doutor Pedro Lessa, 2797, Embaré, Santos / SP - CEP 11025-015",
                telefones: ["(13) 3227-2200", "(13) 99690-9009"],
            },
            {
                nome: "ALMAI Instituto de Neurodesenvolvimento Infantil",
                endereco: "Avenida Conselheiro Nébias, 421, Encruzilhada, Santos / SP - CEP 11045-001",
                telefones: ["(13) 99750-6555", "(13) 99808-8850"],
            },
            {
                nome: "Centro de Reabilitação Neurológica Matheus Alvares - Santos",
                endereco: "Avenida Ana Costa, 146, Vila Matias, Santos / SP - CEP 11060-000",
                telefones: ["(13) 3327-9663", "(13) 97410-7405"],
            },
            {
                nome: "Clínica Terapeutica Bem Estar",
                endereco: "Avenida Afonso Pena, 312, Boqueirão, Santos / SP - CEP 11020-000",
                telefones: ["(13) 99182-0229"],
            },
            {
                nome: "Foncare Santos",
                endereco: "Avenida Ana Costa, 101, Gonzaga, Santos / SP - CEP 11060-001",
                telefones: ["0800 771-2915"],
            },
            {
                nome: "RGD Multiespecialidades Médicas",
                endereco: "Rua Doutor Carvalho de Mendonça, 330, Vila Belmiro, Santos / SP - CEP 11070-101",
                telefones: ["(13) 3224-1331"],
            },
        ],
    },
    {
        especialidade: "Tomografia Computadorizada",
        estabelecimentos: [
            {
                nome: "Centro Clínico Frei Galvão - Santos",
                endereco: "Rua Doutor Heitor de Moraes, 15, Boqueirão, Santos / SP - CEP 11045-570",
                telefones: ["(13) 4090-1680"],
            },
            {
                nome: "Clinica Mult Imagem",
                endereco: "Rua Júlio Conceição, 296, Vila Matias, Santos / SP - CEP 11015-540",
                telefones: ["(13) 3202-1250"],
            },
            {
                nome: "Clínica Radiológica de Santos",
                endereco: "Avenida Conselheiro Nébias, 521, Santos / SP - CEP 11045-001",
                telefones: ["(13) 3228-2525"],
            },
            {
                nome: "Mega Imagem",
                endereco: "Rua Mato Grosso, 79, Boqueirão, Santos / SP - CEP 11055-010",
                telefones: ["(13) 3202-1990"],
            },
            {
                nome: "Pasteur Medicina Diagnóstica",
                endereco: "Avenida Conselheiro Nébias, 741, Boqueirão, Santos / SP - CEP 11045-003",
                telefones: ["(13) 2127-7000", "(13) 3394-0500", "(13) 99182-2237"],
            },
            {
                nome: "Vila Rica Clínica de Imagem",
                endereco: "Avenida Doutor Bernardino de Campos, 16, Vila Belmiro, Santos / SP - CEP 11065-000",
                telefones: ["(13) 3257-2300"],
            },
        ],
    },
    {
        especialidade: "Ultrassonografia Geral",
        estabelecimentos: [
            {
                nome: "Brasil Imagem Medicinadiagnóstica",
                endereco: "Avenida Ana Costa, 259, Gonzaga, Santos / SP - CEP 11060-001",
                telefones: ["(13) 3235-7658", "(13) 3235-7805"],
            },
            {
                nome: "Centro Clínico Frei Galvão - Santos",
                endereco: "Rua Doutor Heitor de Moraes, 15, Boqueirão, Santos / SP - CEP 11045-570",
                telefones: ["(13) 4090-1680"],
            },
            {
                nome: "Clinica Mult Imagem",
                endereco: "Rua Júlio Conceição, 296, Vila Matias, Santos / SP - CEP 11015-540",
                telefones: ["(13) 3202-1250"],
            },
            {
                nome: "Clínica Radiológica de Santos",
                endereco: "Avenida Conselheiro Nébias, 521, Santos / SP - CEP 11045-001",
                telefones: ["(13) 3228-2525"],
            },
            {
                nome: "Mega Imagem",
                endereco: "Rua Mato Grosso, 79, Boqueirão, Santos / SP - CEP 11055-010",
                telefones: ["(13) 3202-1990"],
            },
            {
                nome: "Santos Medical Group",
                endereco: "Avenida Rei Alberto I, 243, Ponta da Praia, Santos / SP - CEP 11030-381",
                telefones: ["(13) 3269-0020", "(13) 3877-7337"],
            },
            {
                nome: "Vila Rica Clínica de Imagem",
                endereco: "Avenida Doutor Bernardino de Campos, 16, Vila Belmiro, Santos / SP - CEP 11065-000",
                telefones: ["(13) 3257-2300"],
            },
        ],
    },
    {
        especialidade: "Urologia",
        estabelecimentos: [
            {
                nome: "APAS - Santos",
                endereco: "Rua Carvalho De Mendonça, 335, Vila Belmiro, Santos / SP - CEP 11070-101",
                telefones: ["(13) 3226-5000"],
            },
            {
                nome: "Centro Clínico Frei Galvão - Santos",
                endereco: "Rua Doutor Heitor de Moraes, 15, Boqueirão, Santos / SP - CEP 11045-570",
                telefones: ["(13) 4090-1680"],
            },
            {
                nome: "Centro Médico Popular Saúde da Família - Santos",
                endereco: "Avenida Nossa Senhora de Fátima, 555, Chico de Paula, Santos / SP - CEP 11085-203",
                telefones: ["0800 521-0101"],
            },
            {
                nome: "Clínica Médica de Angelo LTDA ME",
                endereco: "Avenida Conselheiro Nébias, 580, Boqueirão, Santos / SP - CEP 11045-002",
                telefones: ["(13) 3223-5292", "(13) 3235-6838"],
            },
            {
                nome: "Eloi Guilherme Provinciali Moccellin",
                endereco: "Alameda Armênio Mendes, 66, Ponta da Praia, Santos / SP - CEP 11030-000",
                telefones: ["(13) 3235-4928", "(13) 99716-5690"],
            },
            {
                nome: "Freixo & Bittencout - Clínica Médica e Cirurgias LTDA",
                endereco: "Rua Firmino Barbosa, 155, Boqueirão, Santos / SP - CEP 11055-130",
                telefones: ["(13) 3221-2548", "(13) 3224-5964", "(13) 3232-1305"],
            },
            {
                nome: "Santa Casa de Santos",
                endereco: "Rua Doutor Cláudio Luís da Costa, 50, Jabaquara, Santos / SP - CEP 11075-900",
                telefones: ["(13) 3202-0600"],
            },
            {
                nome: "Santos Medical Group",
                endereco: "Avenida Rei Alberto I, 243, Ponta da Praia, Santos / SP - CEP 11030-381",
                telefones: ["(13) 3269-0020", "(13) 3877-7337"],
            },
            {
                nome: "Ttgeraldeli Servicos Medicos",
                endereco: "Rua Doutor Lobo Viana, 23, Boqueirão, Santos / SP - CEP 11045-120",
                telefones: ["(13) 99759-1048"],
            },
        ],
    }
];

export default RedesSantos;