import './Planos.css'
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import usePageTracking from '../../../hooks/usePageTracking.js';
import CardPlanos from './CardPlanos/CardPlanos.js';
import CopModal from './CopModal/CopModal.js';
import ModalAvisoReajuste from './ReajusteModal/ReajusteModal.js';

const Planos = () => {


    const [showModal, setShowModal] = useState(false);

    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);

    usePageTracking();

    return (
        <section className="page__plans-section">

            <Helmet>
                <title>Planos de Saúde | GoCare Litoral</title>
                <meta name="description"
                    content="Explore os diversos planos de saúde oferecidos pela GoCare Litoral. Planos individuais, familiares e empresariais com cobertura completa e atendimento humanizado na Baixada Santista." />
            </Helmet>

            <div className="page__plans-container">
                <div className="page__plans-text">
                    <div className="page__plans-title">
                        <h2><strong>Valores e Coberturas</strong> de <strong>Planos de Saúde<br /></strong> para o <strong>Litoral</strong></h2>
                    </div>
                    <div className="div-separate"></div>
                </div>

                <CardPlanos />

                <ModalAvisoReajuste />
                
                <div className='modal__container'>
                    <p>
                        Clique no botão a seguir para visualizar a tabela de coparticipação com os valores máximos de terapias, procedimentos e materiais importados.
                    </p>
                    <button onClick={openModal}>
                        Ver Tabela de Coparticipação
                    </button>
                </div>

                <CopModal showModal={showModal} closeModal={closeModal} />

            </div >

        </section >

    )
}

export default Planos;